import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://0ee26fe747410a67491928321f3ba9f4@o4508020955414528.ingest.de.sentry.io/4508020960329808",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});
import { init } from 'astro/virtual-modules/prefetch.js';init()